export const getIncomingOrigin = () => {
    return 'demo'
    // const isProduction = process.env.NODE_ENV === 'production';
    // if (isProduction) {
    //     const hostname = window.location.hostname;
    //     const parts = hostname.split('.');
    //     // Check if the hostname includes ".app.gain"
    //     const index = parts.indexOf('app');
    //     if (index === 1) {
    //         return parts[index - 1];
    //     } else {
    //         return ''; // No subdomain found
    //     }
    // } else {
    //     return 'delivery'; // In development, always use 'delivery'
    // }

};