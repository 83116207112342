"use client";
import { usePathname } from 'next/navigation';
import { useOnNavigate } from "../../hooks/useOnNavigate";
import { CircularProgress } from "@mui/material";
import "./NavigationLoader.css";

export default function NavigationLoader() {
  const loading = useOnNavigate();

  const pathname = usePathname();  
  const pathSegments = pathname.split('/');  
 
  const applyMainDesign = pathSegments[1] === "select-restaurant" || pathSegments[1] === "login" || pathSegments[1] === "signup" || (pathSegments[1] === "login" && pathSegments[2] === "verify");

  return (
    <div 
    className={`navigation-loader-page-wrapper ${applyMainDesign ? "main-design" : ""}`} 
    style={{
      display: loading ? "flex" : "none"
    }}>

     <CircularProgress size={70} 
     style={{ 
      color: applyMainDesign ? "var(--main-color-main-design)" : "var(--main-color)",
      opacity: loading ? 1 : 0 
      }} 
      />

  </div>
  );
}
