import {
  GET_POPULAR_ITEMS_REQUEST,
  GET_POPULAR_ITEMS_SUCCESS,
  GET_POPULAR_ITEMS_ERROR,
  SET_GOT_POPULAR_ITEMS_THE_FIRST_TIME,
  CLEAR_ALL_POPULAR_ITEMS_DATA
} from "../actions/PopularItemsActions";

const initialState = {
  loading: false,
  popularItems: [],
  gotPopularItemsTheFirstTime: false,

  dineInPopularItems: [],
  gotDineInPopularItemsTheFirstTime: false,

  payPopularItems: [],
  gotPayPopularItemsTheFirstTime: false,

  QRPopularItems: [],
  gotQRPopularItemsTheFirstTime: false
};

function popularItems(state = initialState, action) {
  const appSource = action?.payload?.appSource;
  const popularItemsState = appSource === "dine-in"
    ? "dineInPopularItems"
    : appSource === "pay"
      ? "payPopularItems"
      : appSource === "QR"
        ? "QRPopularItems"
        : "popularItems"

    const gotPopularItemsTheFirstTimeState = appSource === "dine-in"
       ? "gotDineInPopularItemsTheFirstTime"
       : appSource === "pay"
       ? "gotPayPopularItemsTheFirstTime"
       : appSource === "QR"
       ? "gotQRPopularItemsTheFirstTime"
       : "gotPopularItemsTheFirstTime";

  switch (action.type) {
    case GET_POPULAR_ITEMS_REQUEST:
      return Object.assign({}, state, {
        loading: state[popularItemsState]?.length > 0 || state[gotPopularItemsTheFirstTimeState] == true ? false : true,
      });
      
    case GET_POPULAR_ITEMS_SUCCESS:
      setTimeout(() => { });
      return Object.assign({}, state, {
        loading: false,
        [popularItemsState]: action.payload.popularItems,
      });

    case GET_POPULAR_ITEMS_ERROR:
      return Object.assign({}, state, {
        loading: false,
        [popularItemsState] : []
      });

      case SET_GOT_POPULAR_ITEMS_THE_FIRST_TIME:
      return Object.assign({}, state, {
          [gotPopularItemsTheFirstTimeState] : action?.payload?.value
      });

    case CLEAR_ALL_POPULAR_ITEMS_DATA:
      return initialState;

    default:
      return state;
  }
}

export default popularItems;
