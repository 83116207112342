"use client"
import React, { Suspense, useState, useEffect } from "react";
import RestaurantsSkeleton from "./components/Skeletons/pages/RestaurantsSkeleton/RestaurantsSkeleton";
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import dynamic from "next/dynamic";
import { useRouter } from "next/navigation";
import Loader from "./components/Loader/Loader";

const MainPage = ({ token, selectedRestaurant, }) => {
    const [loginSkipped, setLoginSkipped] = useState(false);
    const router = useRouter()
    useEffect(() => {
        if (!selectedRestaurant) {
            router.push(`/Restaurants`, undefined, { shallow: true });
        } else {
            router.push(`/${selectedRestaurant}/Home`, undefined, { shallow: true });
        }
    }, [selectedRestaurant, router]);
    return (
        <div>
            <ToastContainer />
            <Suspense fallback={<RestaurantsSkeleton />} >
                <Loader />
            </Suspense>
        </div>
    );
};


const mapStateToProps = (state) => {
    return {
        splashScreen: state.designs.splashScreen,
        token: state.auth.token,
        selectedRestaurant: state.appSource.appSource === "dine-in"
            ? state.restaurants.dineInSelectedRestaurant
            : state.appSource.appSource === "pay"
                ? state.restaurants.paySelectedRestaurant
                : state.appSource.appSource === "QR"
                    ? state.restaurants.QRSelectedRestaurant
                    : state.restaurants.selectedRestaurant,
        getRestaurantsLoading: state.restaurants.getRestaurantsLoading,
    };
};

export default connect(mapStateToProps, null)(MainPage);




