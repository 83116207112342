// /* eslint-disable no-underscore-dangle */
// import { createStore, applyMiddleware } from 'redux';
// import { persistStore, persistReducer, createTransform } from 'redux-persist';
// // import storage from 'redux-persist/lib/storage';
// import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
// import { composeWithDevTools } from '@redux-devtools/extension';
// import thunk from 'redux-thunk';
// import reducers from '../reducers/Reducers';
// // import localforage from "localforage";
// import createWebStorage from 'redux-persist/es/storage/createWebStorage';

// // Create a global transform that will target all `loading` fields
// const resetLoadingTransform = createTransform(
//   // Called when persisting state (filters out `loading` fields)
//   (inboundState, key) => {
//     const newState = { ...inboundState };
//     for (let field in newState) {
//       if (field.toLowerCase().includes('loading')) {
//         delete newState[field]; // Exclude any field containing 'loading'
//       }
//     }
//     return newState;
//   },
//   // Called when rehydrating state (resets `loading` fields to false)
//   (outboundState, key) => {
//     const newState = { ...outboundState };
//     for (let field in newState) {
//       if (field.toLowerCase().includes('loading')) {
//         newState[field] = false; // Set loading fields to false on rehydrate
//       }
//     }
//     return newState;
//   }
// );

// const createNoopStorge = () => {
//   return { 
//     getItem(_key){
//       return Promise.resolve(null)
//     },
//    setItem(_key , value){
//       return Promise.resolve(value)
//     },
//     removeItem(_key){
//       return Promise.resolve()
//     },
//   }
// }

// const storage = 
// typeof window !== "undefined"
// ?createWebStorage("local")
// :createNoopStorge();



// // Configure persistor
// const persistConfig = {
//   key: 'cloud9-user',
//   storage,
//   stateReconciler: autoMergeLevel2,
//   blacklist: ['modalReducer'],
//   transforms: [resetLoadingTransform]
// };

// export const configureStore = () => {
//   const pReducer = persistReducer(persistConfig, reducers);
//   return createStore(
//   pReducer,
//   composeWithDevTools(applyMiddleware(thunk))
// );

//   // return createStore(pReducer, applyMiddleware(thunk), composeWithDevTools());
// };

// export const configurePersistor = (store) => persistStore(store);


import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer, createTransform } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { composeWithDevTools } from '@redux-devtools/extension';
import thunk from 'redux-thunk';
import reducers from '../reducers/Reducers';
import localforage from 'localforage';

// Create a global transform that will target all `loading` fields
const resetLoadingTransform = createTransform(
  // Called when persisting state (filters out `loading` fields)
  (inboundState) => {
    const newState = { ...inboundState };
    for (let field in newState) {
      if (field.toLowerCase().includes('loading')) {
        delete newState[field]; // Exclude any field containing 'loading'
      }
    }
    return newState;
  },
  // Called when rehydrating state (resets `loading` fields to false)
  (outboundState) => {
    const newState = { ...outboundState };
    for (let field in newState) {
      if (field.toLowerCase().includes('loading')) {
        newState[field] = false; // Set loading fields to false on rehydrate
      }
    }
    return newState;
  }
);

// Configure localforage for persistence
localforage.config({
  name: 'cloud9-storage',
  storeName: 'redux-persist', // Table name in IndexedDB
  description: 'Stores Redux state for cloud9 app',
});

// Configure persistor
const persistConfig = {
  key: 'cloud9-user',
  storage: localforage, // Use localforage as the storage backend
  stateReconciler: autoMergeLevel2,
  blacklist: ['modalReducer'],
  transforms: [resetLoadingTransform],
};

export const configureStore = () => {
  const pReducer = persistReducer(persistConfig, reducers);
  return createStore(
    pReducer,
    composeWithDevTools(applyMiddleware(thunk))
  );
};

export const configurePersistor = (store) => persistStore(store);
