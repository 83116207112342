
'use client';
import { Suspense } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { configurePersistor, configureStore } from './redux/store/store';
import ThemeWrapper from './theme';
import ThemeManager from './themeManager'
import Loading from './loading';
import NavigationLoader from './components/LoadingBar/NavigationLoader';
import './injectAtRoot.js';
import './globals.css'

// Redux setup
export const store = configureStore();
export const persistor = configurePersistor(store);

export default function RootLayout({ children }) {


  return (
    <>
      <html lang="en">
        <body>
          <NavigationLoader/>
          <Provider store={store}>
            <PersistGate loading={<div />} persistor={persistor}>
            {/* <Suspense fallback={<Loading/>}>
            <>
            */}
            <ThemeWrapper>
            <ThemeManager />
                {children}
              </ThemeWrapper>
              {/* </>
              </Suspense> */}
            </PersistGate>
          </Provider>
        </body>
      </html>
    </>
  );
}

// 'use client';
// import { Suspense } from 'react';
// import { Provider } from 'react-redux';
// import { PersistGate } from 'redux-persist/integration/react';
// import { configurePersistor, configureStore } from './redux/store/store';
// import ThemeWrapper from './theme';
// import ThemeManager from './themeManager';
// import Loader from './components/Loader/Loader';
// import './globals.css';

// // Redux setup
// export const store = configureStore();
// export const persistor = configurePersistor(store);

// export default function RootLayout({ children }) {
//   return (
//     <>
//       <html lang="en">
//         <body>
//           <Provider store={store}>
//             <PersistGate loading={<div />} persistor={persistor}>
//               <Suspense fallback={<Loader />}>
//                 <ThemeWrapper>
//                   <ThemeManager />
//                   {children}
//                 </ThemeWrapper>
//               </Suspense>
//             </PersistGate>
//           </Provider>
//         </body>
//       </html>
//     </>
//   );
// }
